import Auth from "@/api/Auth";

interface VimConfig {
  imgBaseUrl: string;
  apiBaseUrl: string;
  wsBaseUrl: string;
  clientId: string;
  clientSecret: string;
  host: string;
  httProtocol: string;
  wsProtocol: string;
  scope: string;
  httPort: number;
  contextPath: string;
  wsPort: number;
}

const vimConfig: VimConfig = {
  imgBaseUrl:"https://im.ipay888.com/im",
  apiBaseUrl:"https://im.ipay888.com/im",
  wsBaseUrl:"wss://im.ipay888.com/ws",

  // imgBaseUrl:"http://192.168.43.8:8200/im",
  // apiBaseUrl:"http://192.168.43.8:8200/im",
  // wsBaseUrl:"ws://192.168.43.8:9326",
  clientId: "app",
  clientSecret: "app",
  host: "192.168.43.8",
  httProtocol: "https",
  wsProtocol: "wss",
  scope: "server",
  httPort: 8200,
  contextPath: "/im",
  wsPort: 9326,
};
export default vimConfig;
